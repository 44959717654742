import { generateUUID } from "utils/utils";
import wretch from "wretch";
import {
  ICheckPaymentStatusPayload,
  ICheckPaymentStatusSuccessResponse,
  IPackageGenerateAtomePaymentLinkResponse,
  IPackageGeneratePaymentLinkPayload,
  IPackageGeneratePaymentLinkSuccessResponse,
  IPackageSharePaymentLinkPayload,
  IPackageSharePaymentLinkSuccessResponse,
  IPackageCheckAtomePaymentStatusResponse
} from "./packagePayment.types";

const BASE_URL = `${process.env.NEXT_PUBLIC_PACKAGES_API_URL}`;

export const getPackagePaymentLink = async ({
  user_id,
  package_id,
  product_id,
  coupon_code,
  access_token
}: IPackageGeneratePaymentLinkPayload): Promise<IPackageGeneratePaymentLinkSuccessResponse> => {
  const payload: Omit<IPackageGeneratePaymentLinkPayload, "access_token"> = {
    user_id,
    package_id,
    product_id,
    coupon_code
  };
  return wretch(`${BASE_URL}/api/v1/payments/checkout`)
    .headers({
      Authorization: `Bearer ${access_token}`,
      "x-iq-request-id": generateUUID()
    })
    .post(payload)
    .json();
};

export const sharePackagePaymentLink = async ({
  user_id,
  package_id,
  product_id,
  coupon_code,
  access_token
}: IPackageSharePaymentLinkPayload): Promise<IPackageSharePaymentLinkSuccessResponse> => {
  const payload: Omit<IPackageSharePaymentLinkPayload, "access_token"> = {
    user_id,
    package_id,
    product_id,
    coupon_code
  };
  return wretch(`${BASE_URL}/api/v1/payments/share_payment_link`)
    .headers({
      Authorization: `Bearer ${access_token}`,
      "x-iq-request-id": generateUUID()
    })
    .post(payload)
    .json();
};

export const checkPaymentStatus = async ({
  user_id,
  access_token,
  payment_id
}: ICheckPaymentStatusPayload): Promise<ICheckPaymentStatusSuccessResponse> => {
  return wretch(
    `${BASE_URL}/api/v1/payments/${payment_id}/status?user_id=${user_id}`
  )
    .headers({
      Authorization: `Bearer ${access_token}`,
      "x-iq-request-id": generateUUID()
    })
    .get()
    .json();
};

export const getAtomePaymentLink = async ({
  user_id,
  package_id,
  product_id,
  coupon_code,
  access_token
}: IPackageGeneratePaymentLinkPayload): Promise<IPackageGenerateAtomePaymentLinkResponse> => {
  const payload: Omit<IPackageGeneratePaymentLinkPayload, "access_token"> = {
    user_id,
    package_id,
    product_id,
    coupon_code
  };

  return wretch(`${BASE_URL}/api/v1/payments/atome/checkout`)
    .headers({
      Authorization: `Bearer ${access_token}`,
      "x-iq-request-id": generateUUID()
    })
    .post(payload)
    .json();
};

export const checkAtomePaymentStatus = async ({
  reference_id,
  access_token
}: {
  reference_id: string;
  access_token: string;
}): Promise<IPackageCheckAtomePaymentStatusResponse> => {
  return wretch(
    `${BASE_URL}/api/v1/payments/atome/payment_status?reference_id=${reference_id}`
  )
    .headers({
      Authorization: `Bearer ${access_token}`,
      "x-iq-request-id": generateUUID()
    })
    .get()
    .json();
};
