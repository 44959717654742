import { generateUUID } from "utils/utils";
import wretch from "wretch";
import {
  IPackageDetailsResponse,
  IPackageDetailsV1Response,
  IProductListResponse,
  ICreateSmartfestPaymentLinkResponse
} from "./packageDetails.types";
import { IPackagesSuccessResponseByGradeAndClassType } from "./packageList.types";
import { IPackageAndTenureSummaryDetailsResponse } from "./packageTenureSummaryDetails.types";
import { generateXIQSignature, getCurrentTimeStamp } from "utils/header-utils";

const BASE_URL = `${process.env.NEXT_PUBLIC_PACKAGES_API_URL}`;

export const getPackageById = (
  packageId: string,
  access_token?: string
): Promise<IPackageDetailsResponse> => {
  const headers: {
    "X-Locale": string;
    Authorization?: string;
    "x-iq-request-id": string;
  } = {
    "X-Locale": "id",
    "x-iq-request-id": generateUUID()
  };
  if (access_token) {
    headers["Authorization"] = `Bearer ${access_token}`;
  }

  return wretch(`${BASE_URL}/api/v2/packages/${packageId}`)
    .headers(headers)
    .get()
    .json();
};

export const getPackageByIdV1 = (
  payload: string,
  platform: string,
  packageId: string,
  access_token?: string
): Promise<IPackageDetailsV1Response> => {
  const user = JSON.stringify(payload);
  const timestamp = getCurrentTimeStamp();
  const signature = generateXIQSignature(platform, timestamp, user);

  const headers: {
    "X-IQ-Platform": string;
    "X-IQ-Timestamp": string;
    "X-IQ-User": string;
    "X-IQ-Signature": string;
    Authorization?: string;
  } = {
    "X-IQ-Platform": platform,
    "X-IQ-Timestamp": timestamp,
    "X-IQ-User": user,
    "X-IQ-Signature": signature
  };

  if (access_token) {
    headers["Authorization"] = `Bearer ${access_token}`;
  }

  return wretch(`${BASE_URL}/api/v1/packages/${packageId}`)
    .headers(headers)
    .get()
    .json();
};

export const getPackagesForGradeAndClassType = (
  grade: string,
  class_type: string
): Promise<IPackagesSuccessResponseByGradeAndClassType> => {
  return wretch(
    `${BASE_URL}/api/v2/packages?grade=${grade}&class_type=${class_type}&show_in_web=true`
  )
    .headers({
      "X-Locale": "id",
      "x-iq-request-id": generateUUID()
    })
    .get()
    .json();
};

export const getPackageAndTenureSummaryDetails = ({
  packageId,
  tenureId,
  coupon_code,
  user_id
}: {
  tenureId: string;
  packageId: string;
  coupon_code: string;
  user_id?: string;
}): Promise<IPackageAndTenureSummaryDetailsResponse> => {
  return wretch(
    `${BASE_URL}/api/v1/packages/${packageId}/products/${tenureId}?coupon_code=${coupon_code}&user_id=${
      user_id || ""
    }`
  )
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .get()
    .json();
};

export const getProductsList = ({
  external_id,
  access_token
}: {
  external_id?: string;
  access_token?: string;
}): Promise<IProductListResponse> => {
  const headers: {
    "X-Locale": string;
    "X-IQ-Platform": string;
    Authorization?: string;
    "x-iq-request-id": string;
  } = {
    "X-Locale": "id",
    "X-IQ-Platform": "desktopWeb",
    "x-iq-request-id": generateUUID()
  };
  if (access_token) {
    headers["Authorization"] = `Bearer ${access_token}`;
  }

  return wretch(`${BASE_URL}/api/v1/products/list?external_id=${external_id}`)
    .headers(headers)
    .get()
    .json();
};

export const createSmartfestRegisterPaymentLink = (
  phoneNumber: string,
  metaData: Record<string, string> = {}
): Promise<ICreateSmartfestPaymentLinkResponse> => {
  const headers: {
    "X-Locale": string;
    "X-IQ-Platform": string;
    "x-iq-request-id": string;
  } = {
    "X-Locale": "id",
    "X-IQ-Platform": "desktopWeb",
    "x-iq-request-id": generateUUID()
  };

  return wretch(`${BASE_URL}/api/v2/smart_fest/payment_link`)
    .headers(headers)
    .post({
      phone_number: phoneNumber,
      payment_meta: metaData
    })
    .json();
};
