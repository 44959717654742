import React from "react";
import { Whatsapp } from "components/icons/whatsapp";
import {
  StickyWrapper,
  SelectedPackageName,
  HelpDiv,
  CTAWithHelpWrapper,
  HelpText,
  WhatsAppWrapper,
  WhatsappLink,
  WhatsappLinkWrapper,
  ButtonWrapper
} from "./package-selection-sticky.styles";
import Link from "next/link";
import { IPackageSelectionSticky } from "./package-selection-sticky.types";
import { usePackageSelectionSticky } from "./usePackageSelectionSticky";

export const PackageSelectionSticky: React.FC<IPackageSelectionSticky> = ({
  selectedPackage,
  isPackageClickedOnMobile,
  onProceed
}) => {
  const { handleContactColearnClick, handleProceed } =
    usePackageSelectionSticky(
      selectedPackage,
      isPackageClickedOnMobile,
      onProceed
    );

  return (
    <StickyWrapper isPackageClickedOnMobile={isPackageClickedOnMobile}>
      <SelectedPackageName>{selectedPackage.name}</SelectedPackageName>
      <CTAWithHelpWrapper>
        <HelpDiv>
          <HelpText>Belum yakin?</HelpText>
          <WhatsappLinkWrapper>
            <WhatsAppWrapper>
              <Whatsapp height={25} width={25} />
            </WhatsAppWrapper>
            <Link href="https://wa.link/oxcb9x" passHref>
              <WhatsappLink
                onClick={handleContactColearnClick}
                target="_blank"
                rel="noreferrer">
                Hubungi CoLearn
              </WhatsappLink>
            </Link>
          </WhatsappLinkWrapper>
        </HelpDiv>
        <ButtonWrapper onClick={handleProceed} variant="primary">
          Dapetin info pendaftaran
        </ButtonWrapper>
      </CTAWithHelpWrapper>
    </StickyWrapper>
  );
};
