import createHMac from "create-hmac";

const encodeToBase64 = (string: string) => {
  return Buffer.from(string).toString("base64");
};

export const getCurrentTimeStamp: () => string = () => {
  const date = new Date();
  return date.toISOString();
};

const generateHex = (base64String: string): string => {
  const salt = process.env.NEXT_HASH_SALT;
  let hash;
  if (salt) {
    hash = createHMac("sha256", salt);
    hash.update(base64String);
    const value = hash.digest("hex");
    return value;
  }
  return "";
};

export const generateXIQSignature = (
  platform: string,
  timestamp: string,
  user?: string
): string => {
  const data = `${platform}+${timestamp}${(user && `+${user}`) || ""}`;
  const datab64 = encodeToBase64(data);
  const dataHex = generateHex(datab64);
  return dataHex;
};
