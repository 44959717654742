import styled from "styled-components";
import Image from "next/legacy/image";
import { breakpoints } from "styles";

export const Chip = styled.div<{ backgroundColor: string }>`
  display: inline-flex;
  border-radius: 20px;
  justify-content: center;
  gap: 4px;
  background: ${({ backgroundColor }) => backgroundColor};
  padding: 4px 8px;
  @media ${breakpoints.tablet} {
    padding: 6px 14px;
  }
`;
export const ChipIcon = styled(Image)``;

export const ChipText = styled.span<{ color: string }>`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ color }) => color};
  font-weight: bold;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
`;
