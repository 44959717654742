import { createPayloadForWebCheckoutTrackingEvent } from "utils/mixpanel/web-checkout/web-checkout-mixpanel-utils";
import trackEvent from "services/mixpanel/mixpanel";
import { paketPageEvents } from "services/mixpanel/web-checkout-events";
import { useAuth } from "stores/auth/auth-context";
import { ISelectedPackageDetails } from "./package-selection-sticky.types";

export const usePackageSelectionSticky = (
  selectedPackage: ISelectedPackageDetails,
  isPackageClickedOnMobile: boolean,
  onProceed: () => void
): {
  handleContactColearnClick: () => void;
  handleProceed: () => void;
} => {
  const { user } = useAuth();

  const handleContactColearnClick = () => {
    const eventName = paketPageEvents.contactColearnClicked;
    const payload = createPayloadForWebCheckoutTrackingEvent({
      useAuthData: user
    });
    trackEvent({
      eventName,
      payload
    });
  };

  const handleProceed = () => {
    const eventName = paketPageEvents.packageSelectedContinue;
    const payload = createPayloadForWebCheckoutTrackingEvent({
      useAuthData: user
    });
    trackEvent({
      eventName,
      payload: {
        ...payload,
        Level: selectedPackage.class_type,
        Class: selectedPackage.grade,
        "Package Type": selectedPackage.name,
        Section: isPackageClickedOnMobile
          ? "Choose Package Section"
          : "Selected Package Panel"
      }
    });
    onProceed();
  };

  return { handleProceed, handleContactColearnClick };
};
