import React from "react";
import { ISubjectTagProps } from "./subject-tag.types";
import { Chip, ChipIcon, ChipText } from "./subject-tag.styles";
const SubjectTag: React.FC<ISubjectTagProps> = ({
  backgroundColor,
  color,
  iconSrc,
  text
}) => {
  return (
    <Chip backgroundColor={backgroundColor}>
      {iconSrc && (
        <ChipIcon height={16} width={16} src={iconSrc} alt="subject tag" />
      )}
      <ChipText color={color}>{text}</ChipText>
    </Chip>
  );
};

export { SubjectTag };
