export const currencyDelimeter = (rupiah: number): string => {
  const rupiahString = String(rupiah);

  if (rupiah >= 1000 && rupiah < 10000) {
    return `${rupiahString[0]}.${rupiahString.slice(1)}`;
  } else if (rupiah >= 10000 && rupiah < 100000) {
    return `${rupiahString.slice(0, 2)}.${rupiahString.slice(2)}`;
  } else if (rupiah >= 100000 && rupiah < 1000000) {
    return `${rupiahString.slice(0, 3)}.${rupiahString.slice(3)}`;
  } else if (rupiah >= 1000000 && rupiah < 10000000) {
    return `${rupiahString[0]}.${rupiahString.slice(1, 4)}.${rupiahString.slice(
      4
    )}`;
  } else {
    return rupiahString;
  }
};

export const calculateDiscount = (price: number, discount: number): number => {
  const discountedAmount: number = (price * (100 - discount)) / 100;
  return discountedAmount;
};

export const calculateInstalment = (
  price: number,
  discount: number
): number => {
  const instalment: number = calculateDiscount(price, discount) / 12;
  return instalment;
};

export const calculateAmountPayable = (
  price: number,
  discount: number
): number => {
  const amountPayable: number = price - calculateDiscount(price, discount);
  return amountPayable;
};

export const getMonthAndYear = (ISODate: string): string => {
  const months: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const month: string = months[new Date(ISODate).getMonth()];
  const year: number = new Date(ISODate).getFullYear();
  return `${month} ${year}`;
};

export const getDiscountFromDiscountString = (
  discountString: string
): string | null => {
  const discount: RegExpMatchArray | null = discountString.match(/\d/g);
  if (discount) return discount.join("");
  return null;
};
