import { generateUUID } from "utils/utils";
import wretch from "wretch";

const BASE_URL = process.env.NEXT_PUBLIC_BE_URL;

export const getSitemap = (sitemapName: string): Promise<string> => {
  return wretch(`${BASE_URL}/sitemaps/${sitemapName}`)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .get()
    .text();
};
