import React from "react";
import { IIcon } from "./icons.types";

const Whatsapp: React.FC<IIcon> = ({
  width = 20,
  height = 20,
  color = "currentColor"
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.74057 3.85588C7.38037 2.69062 9.50289 2.2457 11.4905 2.64119C12.7777 2.89458 13.9743 3.48309 14.9583 4.3467C16.3508 5.56121 17.2391 7.24797 17.4499 9.07836C17.5911 10.2868 17.4327 11.5113 16.9885 12.6448C16.4632 13.9866 15.5333 15.166 14.3549 15.9993C13.2194 16.8053 11.8785 17.2765 10.4861 17.3588C9.09242 17.444 7.70271 17.1382 6.47529 16.476L2.8341 17.4139C2.68531 17.4523 2.55066 17.3155 2.59134 17.1673L3.54706 13.6864C3.56681 13.6439 3.54079 13.6074 3.51709 13.5741L3.51157 13.5664C2.72606 12.1714 2.41481 10.5612 2.62423 8.97596C2.76247 7.95661 3.1094 6.9763 3.64359 6.09564C4.17777 5.21498 4.88785 4.45269 5.72992 3.85588H5.74057ZM7.8136 6.26494C7.98042 6.26847 8.09045 6.41678 8.15434 6.54743C8.27603 6.80974 8.38381 7.07666 8.4916 7.34358C8.57244 7.54377 8.65328 7.74396 8.73998 7.9422C8.78258 8.03754 8.81097 8.14348 8.77548 8.24588C8.69539 8.47162 8.53499 8.64942 8.37583 8.82586C8.32337 8.88401 8.27105 8.94201 8.22178 9.00153C8.15079 9.08275 8.15789 9.19927 8.21468 9.28402C8.7604 10.3387 9.69532 11.1423 10.8235 11.5263C10.9299 11.5722 11.0577 11.5616 11.1323 11.4698C11.3451 11.2335 11.5464 10.9872 11.7356 10.7318C11.8982 10.5208 12.0397 10.5794 12.2512 10.6671L12.2539 10.6682L13.6168 11.3215C13.7233 11.3674 13.8298 11.445 13.8191 11.5686C13.8901 12.4867 13.0631 13.2 12.1971 13.2282C9.96096 13.0658 8.43474 11.8299 7.33443 10.4034C7.30929 10.3686 7.28405 10.3337 7.25878 10.2988C6.92005 9.83139 6.57526 9.35557 6.3903 8.80026C6.20219 8.27766 6.19509 7.6809 6.4187 7.16537C6.76299 6.4768 7.06468 6.21197 7.82425 6.25435L7.8136 6.26494Z"
        fill={color}
      />
    </svg>
  );
};

export { Whatsapp };
