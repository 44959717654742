import wretch from "wretch";
import {
  ISubmitPackageSubscriptionPayload,
  ISubmitPackageSubscriptionResponse
} from "./slotSelection.types";

const BASE_URL = `${process.env.NEXT_PUBLIC_BASE_API}`;

export const createPackageSubscription = (
  payload: ISubmitPackageSubscriptionPayload,
  token: string
): Promise<ISubmitPackageSubscriptionResponse> => {
  return wretch(`${BASE_URL}/sessions/v1/package-subscriptions/details`)
    .auth(`Bearer ${token}`)
    .post(payload)
    .json();
};
