import { Button } from "components/atoms/button/button";
import styled from "styled-components";
import { breakpoints, zIndex } from "styles";

export const StickyWrapper = styled.div<{ isPackageClickedOnMobile: boolean }>`
  position: fixed;
  bottom: 0;
  box-shadow: 0px 0px 30px ${({ theme }) => theme.colors.transparent.white10};
  display: flex;
  padding: 12px 15px;
  right: 0;
  left: 0;
  z-index: ${zIndex.stickySelectedPackage};
  background: ${({ theme }) => theme.colors.white};
  justify-content: space-between;
  align-items: center;
  @media ${breakpoints.laptop} {
    display: flex;
    padding: 20px 150px 15px;
  }
`;

export const SelectedPackageName = styled.h5`
  display: none;
  @media ${breakpoints.tablet} {
    display: unset;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.neutral[900]};
    margin: 0;
    font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  }
`;
export const CTAWithHelpWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  @media ${breakpoints.tablet} {
    width: unset;
    flex-direction: row;
    gap: 20px;
  }
`;

export const HelpDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media ${breakpoints.tablet} {
    display: block;
  }
`;

export const HelpText = styled.p`
  display: inline-block;
  text-align: right;
  font-size: ${({ theme }) => theme.fontSizes["sm"]};
  margin: 4px 0;
  @media ${breakpoints.tablet} {
    display: block;
  }
`;

export const WhatsAppWrapper = styled.span`
  color: ${({ theme }) => theme.colors.green["500"]};
`;
export const WhatsappLink = styled.a`
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  &:link,
  &:visited {
    color: ${({ theme }) => theme.colors.brand[500]};
  }
`;
export const WhatsappLinkWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  @media ${breakpoints.tablet} {
    display: flex;
  }
`;

export const ButtonWrapper = styled(Button)`
  height: auto;
`;
