import wretch from "wretch";
import * as Sentry from "@sentry/nextjs";
import QueryStringAddon from "wretch/addons/queryString";

import {
  IGetVideoDetailsResponse,
  IGetVideoRecommendationsData,
  IGetQuestions,
  IQuestionParams,
  VideoAPIData,
  IGetPushToAppDeeplink,
  GetPushToAppDeeplinkParams,
  IGetVideoSlugs,
  IGetSubscriptionPaymentsResponse,
  IGetSubscriptionPaymentById
} from "./tanyaService.types";
import { generateUUID } from "utils/utils";

const BASE_URL = process.env.NEXT_PUBLIC_ROOT_API_URL;
const TANYA_BASE_URL = process.env.NEXT_PUBLIC_BASE_API;
const defaultPerPage = 10;

export const getVideoData = (
  questionId: string,
  requestHeaders?: Record<string, unknown>
): Promise<IGetVideoDetailsResponse> => {
  try {
    return wretch(
      `${BASE_URL}/questions/${questionId}?includes[]=similar_questions`
    )
      .headers({
        "x-iq-request-id": generateUUID(),
        ...requestHeaders
      })
      .get()
      .json();
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const getVideoSlugs = (): Promise<IGetVideoSlugs[]> => {
  return wretch(`${BASE_URL}/questions/slugs`)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .get()
    .json();
};

export const getVideoRecommendationsData = (
  questionId: string
): Promise<IGetVideoRecommendationsData> => {
  return wretch(`${BASE_URL}/questions/${questionId}/recommendations`)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .get()
    .json();
};

export const getQuestions = (
  page: number,
  questionParams?: IQuestionParams,
  perPage?: number
): Promise<IGetQuestions> => {
  return wretch(`${BASE_URL}/questions`)
    .addon(QueryStringAddon)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .query({ page, per_page: perPage ?? defaultPerPage, ...questionParams })
    .get()
    .json();
};

export const getTanyaVideoData = async (
  doubtID: string
): Promise<VideoAPIData> => {
  https: return wretch(`${BASE_URL}doubts/${doubtID}/play`)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .options({ credentials: "include", mode: "cors" })
    .get()
    .res(async (res) => {
      // Data from Tanya API is not returned in the expected format
      // so we need to format it manually
      const data = await res.json();
      const { id, questionId } = data.doubt_data;
      const topics = data.doubt_data.tags.topic;
      const sections = data.doubt_data.tags.section;
      const subjects = data.doubt_data.tags.subject;
      const chapters = data.doubt_data.tags.chapter;
      const grades = [data.doubt_data.tags.primaryTopic.grade];
      const title = data.doubt_data.source.bookDetails.title;
      const cover_image = data.doubt_data.solution.thumbnail;
      const video_url = data.doubt_data.solution.convertedVideoUrl;
      // Adding these as a temp fix to avoid type error, won't be used in video player just dummy values
      const slug = data.doubt_data.source.bookDetails.title;
      const slugs = {
        subjects,
        grades,
        sections,
        chapters,
        topics,
        title
      };
      const videoData = {
        id,
        questionId,
        topics,
        subjects,
        chapters,
        title,
        sections,
        grades,
        cover_image,
        video_url,
        slug,
        slugs,
        upload_date: "",
        duration: "",
        transcript: "",
        // upvoteCount: 0,
        duration_in_seconds: 0
      };
      return videoData;
    });
};

export const getPushToAppDeeplink = ({
  campaign,
  channel,
  feature,
  banner_experiment_variant,
  questionId
}: GetPushToAppDeeplinkParams): Promise<IGetPushToAppDeeplink> => {
  return wretch(`${BASE_URL}/questions/${questionId}/push-to-app-deeplink`)
    .headers({
      "x-iq-request-id": generateUUID()
    })
    .post({ campaign, channel, feature, banner_experiment_variant })
    .json();
};

export const getSubscriptionPayments = ({
  status,
  accessToken
}: {
  status?: string;
  accessToken: string;
}): Promise<IGetSubscriptionPaymentsResponse> => {
  const headers: {
    "X-Locale"?: string;
    Authorization?: string;
    "x-iq-request-id": string;
  } = {
    "x-iq-request-id": generateUUID()
  };

  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`;
  }

  return wretch(
    `${TANYA_BASE_URL}/package-purchases/v1/subscription-payments/?status=${status}`
  )
    .headers(headers)
    .get()
    .json();
};

export const getSubscriptionPaymentById = (
  paymentId: string,
  accessToken: string
): Promise<IGetSubscriptionPaymentById> => {
  const headers = {
    "x-iq-request-id": generateUUID(),
    Authorization: `Bearer ${accessToken}`
  };
  return wretch(
    `${TANYA_BASE_URL}/package-purchases/v1/subscription-payments/${paymentId}`
  )
    .headers(headers)
    .get()
    .json();
};
