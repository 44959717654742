import styled from "styled-components";
import Link from "next/link";
import { breakpoints, sizes, zIndex } from "styles";

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  overflow-y: hidden;

  @media ${breakpoints.tablet} {
    flex-direction: row;
    align-items: start;
    padding-top: 3%;
    justify-content: center;
    gap: 60px;
  }
`;
export const MobileCTAWrapper = styled.div`
  display: flex;
  position: sticky;
  background: ${({ theme }) => theme.colors.white};
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  padding: 16px;
  z-index: ${zIndex.stickySelectedPackage};
  @media ${breakpoints.tablet} {
    display: none;
  }
`;
export const CTALink = styled(Link)`
  width: 100%;
`;

export const Card = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 24px 16px;
  position: relative;
  @media ${breakpoints.tablet} {
    flex-grow: unset;
    padding: 32px;
    width: ${sizes.checkoutCardWidth.tablet};
    box-shadow: 0 0 30px ${({ theme }) => theme.colors.transparent.white10};
  }
`;

export const CardHeading = styled.h1`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  line-height: 40px;
  color: ${({ theme }) => theme.colors.neutral[900]};
  margin: 0;
`;

export const CardSubHeading = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.neutral[600]};
  margin: 4px 0 12px 0;
  font-weight: normal;
  line-height: 24px;
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["md"]};
    line-height: 24px;
  }
`;

export const SummaryCardWrapper = styled.div`
  display: none;
  @media ${breakpoints.laptop} {
    display: block;
  }
`;
export const SummaryPageSummaryCardWrapper = styled.div`
  @media ${breakpoints.laptop} {
    display: block;
  }
`;
export const ButtonContentWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const TenurePageWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 50px;
  @media ${breakpoints.tablet} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: unset;
  }
`;
export const SummaryPageMain = styled(Main)`
  flex-direction: column-reverse;
  @media ${breakpoints.tablet} {
    flex-direction: row;
  }
`;

export const PaymentBreakdownWrapper = styled.div`
  @media ${breakpoints.tablet} {
    display: none;
  }
`;
