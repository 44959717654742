import wretch from "wretch";
import * as Sentry from "@sentry/nextjs";
import {
  IGenerateOTPPayload,
  IGenerateOTPResponse,
  IValidateOTPResponse,
  IValidateOTPPayload,
  UserClaim,
  ILoginEligibilityResponse
} from "./authService.types";

const THESEUS_BASE_URL = `${process.env.NEXT_PUBLIC_BASE_API}/theseus`;
const BASE_URL = `${process.env.NEXT_PUBLIC_BASE_API}/user-management`;

import { getCurrentTimeStamp, generateXIQSignature } from "utils/header-utils";
import { ICredentials } from "types/next-auth";
import { generateUUID } from "utils/utils";

export const loginEligibility = async (payload: {
  phone_number: string;
  user_claim: UserClaim;
}): Promise<ILoginEligibilityResponse> => {
  try {
    return wretch(`${THESEUS_BASE_URL}/api/v1/auth/eligibility_check`)
      .headers({
        "x-iq-request-id": generateUUID(),
        "X-IQ-Platform": "desktopWeb"
      })
      .post(payload)
      .json();
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTag("where", "authService");
      scope.setUser({ id: payload.phone_number });
      scope.setExtra("info", { step: "loginEligibility" });
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const generateOTP = async (
  mobileNumber: string,
  platform: string
): Promise<IGenerateOTPResponse> => {
  const payload: IGenerateOTPPayload = {
    phone: mobileNumber
  };
  const timestamp = getCurrentTimeStamp();
  const user = JSON.stringify(payload);
  const signature = generateXIQSignature(platform, timestamp, user);
  try {
    return wretch(`${BASE_URL}/v4/users/generate`)
      .headers({
        "x-iq-platform": platform,
        "x-iq-timestamp": timestamp,
        "x-iq-signature": signature,
        "x-iq-request-id": generateUUID()
      })
      .post(payload)
      .json();
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTag("where", "authService");
      scope.setUser({ id: mobileNumber });
      scope.setExtra("info", { step: "generateOTP" });
      Sentry.captureException(error);
    });
    throw error;
  }
};

export const validateOTP = (
  credentials: unknown
): Promise<IValidateOTPResponse> => {
  const { otp, token, isWhatsappConsentGiven, phoneNumber } =
    credentials as ICredentials;
  const payload: IValidateOTPPayload = {
    otp,
    notification_consent: isWhatsappConsentGiven === "true" ? true : false
  };

  try {
    return wretch(`${BASE_URL}/v4/users/validate`)
      .headers({
        "x-iq-request-id": generateUUID()
      })
      .auth(`Bearer ${token}`)
      .post(payload)
      .json();
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTag("where", "authService");
      scope.setUser({ id: phoneNumber });
      scope.setExtra("info", { step: "validateOTP" });
      Sentry.captureException(error);
    });
    throw error;
  }
};
